export default {
    data() {
        return {
            palette: {
                theme1: '#e1b284',
                theme1Hover: '#f7d9bc',
            }
        }
    }
}
