var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('top-bar',{attrs:{"home-url":_vm.homeUrl,"plans-url":_vm.plansUrl,"account-url":_vm.accountUrl,"app-name":_vm.appName,"initially-minimized":_vm.initiallyMinimized,"include-burger-button":"1","include-border-line":"0","brand-src":_vm.brandSrc},on:{"minimize-switched":_vm.minimizeSwitch}}),_vm._v(" "),_c('div',{staticClass:"container pb-4"},[_c('div',{staticClass:"row"},[(_vm.alertMessage !== '')?_c('div',{staticClass:"col-12 mb-4"},[_c('div',{class:['alert', 'alert-'+_vm.alertType]},[_vm._v("\n                    "+_vm._s(_vm.alertMessage)+"\n                ")])]):(Object.keys(_vm.errors).length > 0)?_c('div',{staticClass:"col-12 mb-4"},[_c('div',{staticClass:"alert alert-danger"},_vm._l((_vm.errors),function(errorArray,arrayIndex){return _c('div',{key:arrayIndex},_vm._l((errorArray),function(error,index){return _c('div',{key:index},[_vm._v("\n                            "+_vm._s(error)+"\n                        ")])}),0)}),0)]):_vm._e(),_vm._v(" "),_c('div',{class:[
                'col-12',
                _vm.isMinimized ? 'col-md-auto' : 'col-md-4',
                _vm.isMinimized ? '' : 'col-lg-3',
                _vm.isMinimized ? '' : 'col-xl-2',
                'no-gutters',
                'mb-4',
                'mb-md-0' ]},[_c('side-bar',{attrs:{"current-url":_vm.currentUrl,"wealth-url":_vm.wealthUrl,"assets-url":_vm.assetsUrl,"debts-url":_vm.debtsUrl,"currencies-url":_vm.currenciesUrl,"settings-url":_vm.settingsUrl,"is-minimized":_vm.isMinimized}})],1),_vm._v(" "),_vm._t("default")],2)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }